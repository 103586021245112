import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import renderHydrogen from '@shopify/hydrogen/entry-client'

const ClientWrapper = props => props.children

Sentry.init({
  dsn: 'https://0f68f100379449b0bece3a6cfeb9e4c6@o1098021.ingest.sentry.io/6623246',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
})

export default renderHydrogen(ClientWrapper)
